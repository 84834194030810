import React, { useRef, useEffect } from "react";
import "./MultileTypeFilter.scss";
import { FilterDataObj, FilterDataType } from "../../assetList/types";
import DownArrowIcon from "../assets/dropdownIcon.svg";
import FilterLabel from "./FilterLabel";
import FilterHeading from "./FilterHeading";

type PropTypes = {
  data: FilterDataType;
  index: number;
  activeInput: string;
  setActiveInput: (value: string) => void;
  handleSelectedFilter: (obj: FilterDataObj, index: number) => void;
  filterHierarchyData: (
    index: number,
    group: string,
    hierarchyList: string[]
  ) => void;
  searchMultipleTypeFilterData: (value: string, index: number) => void;
  handleFilterHeading: (index: number, group: string) => void;
  disabled: boolean;
};

function MultileTypeFilter({
  data,
  index,
  activeInput,
  setActiveInput,
  handleSelectedFilter,
  filterHierarchyData,
  searchMultipleTypeFilterData,
  handleFilterHeading,
  disabled,
}: PropTypes) {
  const lastGroupNameRef = useRef("");
  const containerRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setActiveInput("")
      }
    };
    document.addEventListener('mousedown', handleClickOutside);   
  }, []);

  const checkLastGroupName = (value: string) => {
    if (lastGroupNameRef.current !== value) {
      lastGroupNameRef.current = value;
      return true;
    }
    return false;
  };

  const checkVisibleForData = (isVisible: boolean, group: string) => {
    return isVisible && data.selectedChildren.includes(group);
  };

  const checkDividerLineVisible = (group: string, childIndex: number) => {
    const tempIndex = data.data.findLastIndex((item: FilterDataObj) => item.group === group);
    const status = childIndex === tempIndex;
    return status;
  };

  if (activeInput === data.key) {
    return (
      <div className="multipleTypeFilterMainDiv" ref={containerRef}>
        <div className="filterSelectorMainContainer">
          <div className="chipFilterSelector">
            <input
              type="text"
              placeholder="Search..."
              value={data.searchValue}
              className="searchFilterInput"
              onChange={(e) =>
                searchMultipleTypeFilterData(e.target.value, index)
              }
            />
            <img src={DownArrowIcon} onClick={() => setActiveInput("")} />
          </div>
          <div className="multipleFilterChipList">
            {data.data.map((obj: FilterDataObj, childIndex: number) => (
              <>
                {checkLastGroupName(obj.group) && (
                  <FilterHeading
                  title={obj.group === "Asset Category Live" ? "Asset Category".toUpperCase() : obj.group.toUpperCase()}
                    selectedChildren={data.selectedChildren}
                    group={obj.group}
                    handleFilterHeading={handleFilterHeading}
                    index={index}
                  />
                )}
                {checkVisibleForData(obj.isVisible, obj.group) && (
                  <FilterLabel
                    isSelected={obj.isSelected}
                    title={obj.name}
                    onClick={() => {
                      handleSelectedFilter(obj, index);
                      filterHierarchyData(index, obj.group, data.hierarchyList);
                    }}
                  />
                )}
                {checkDividerLineVisible(obj.group, childIndex) && (
                  <div className="horizontalLineDivider" />
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <button
      className="selectorMainContainer"
      onClick={() => setActiveInput(data.key)}
      disabled={disabled}
    >
      <div className="filterSelector">
        <p className="chipFilterPlaceholder">
          {data.placeholder}{" "}
          {data.selectedCount > 0 && (
            <span className="selectedFilterCount">{data.selectedCount}</span>
          )}
        </p>
        <img src={DownArrowIcon} className="dropdownIcon" />
      </div>
    </button>
  );
}

export default MultileTypeFilter;
