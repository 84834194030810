import React from 'react'
import InActiveMeterIcon from "../../../assets/icons/metersInActiveIcon.svg"
import EnergyVoltsIcon from "../../../assets/icons/energyVoltsIcon.svg"
import ActiveMeterIcon from "../../../assets/icons/activeEnergyIcon.svg"
import EnergyNavIcon from "../../../assets/icons/energyNavIcon.svg"
import MainMeterActive from '../../../assets/icons/MainMeterECActive.svg';
import MainMeterNotActive from '../../../assets/icons/MainMeterECNonActive.svg';
import * as R from "ramda";
import "./MeterCard.scss"

function MeterCard(props: any) {
    const { data, changeMeterId, active } = props;
    const formatter = new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    return (
        <div className={active ? 'meterCard activeMeter' : 'meterCard'} onClick={() => changeMeterId(data.fixedAssetId)}>
            <div className='metricIconsContainer'>
                {data.classEnumDesc === 'Sub meter' ? <img src={!active ? InActiveMeterIcon : ActiveMeterIcon} alt="icon" className="inactiveIcon" /> : <img src={!active ? MainMeterNotActive : MainMeterActive} alt="icon" className="inactiveIcon" />}
                {active && <img src={EnergyNavIcon} alt="icon" />}
            </div>

            <p className="meterCardHeader"><span>{data.fixedAssetName}</span></p>
            <div className="meterValuesCont">
                <img src={EnergyVoltsIcon} alt="icon" className="voltsIcon" />
                <p className="meterValues">
                    {
                        !R.isEmpty(data?.energyConsumption) && !R.isNil(data?.energyConsumption) && data?.energyConsumption !== 'undefined' && typeof (data?.energyConsumption) === 'number' && formatter.format(data?.energyConsumption).toLocaleString()
                    } {data?.abbreviation}</p>
            </div>
            {data.classEnumDesc !== 'Sub meter' && <p className='mainMeterDesc'> {data.classEnumDesc} </p>}
        </div>
    )
}

export default MeterCard