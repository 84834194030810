import ReactEcharts from "echarts-for-react";

import moment from "moment";

import React, { useEffect, useRef, useState } from "react";

import Modal from 'react-bootstrap/Modal';

import { API_BASE_URL } from "../../Constants/constants";

import closeIcon from '../../assets/icons/IconClose.svg';

import ChartBackIcon from "../../assets/icons/chartBackIcon.svg";

import ListDownIcon from "../../assets/icons/listDownIcon.svg";

import ResetIcon from "../../assets/icons/resetIcon.svg";

import SettingsIcon from "../../assets/icons/settingsIcon.svg";

import { useStateValue } from "../../redux/StateProvider";

import DisplayError from "../DisplayError/DisplayError";

import LoaderStyle from "../LoaderStyle/LoaderStyle";

import './EnergyDistribution.scss';

import { toast } from "react-toastify";

const _ = require('lodash');



interface EnergyDistributionProps {

  showSettings: boolean;

  setEnergyDistributionSettingsPopup: Function;

  displayNone: boolean;

}

interface EnergySummaryEntry {

  fixedAssetIdFrom: string;

  classEnumIdFrom: string;

  fixedAssetIdFromName: string;

  fixedAssetTypeIdFrom: string;

  fixedAssetIdToName: string;

  consumption: any;

}

interface dropdownTypes {

  selectedEnergyUnit: string,

  selectedFacility: string,

  selectedFacilityId: string

}

type facilityListType = {

  facilityId: string;

  facilityName: string;

};

type enablePopupTypes = {

  showUnitPopup: boolean,

  showFacilityDropDown: boolean,

  showSettingsFacDropDown: boolean

}



const EnergyDistribution: React.FC<EnergyDistributionProps> = (props) => {

  const { showSettings, setEnergyDistributionSettingsPopup, displayNone } = props

  const [showSettingsPopup, setShowSettingsPopup] = useState(false);

  const [showDefaultDropdown, setShowDefaultDropdown] = useState(false);

  const [facilityList, setFacilityList] = useState<facilityListType[]>([]);

  const [showLoader, setLoader] = useState(true);

  const [showBackButton, setIsBackButtonEnabled] = useState(false);

  const [showEmptyMessage, setShowEmptyMessage] = useState(true);

  const [showErrorResponse, setShowErrorResponse] = useState(false);



  const [dropDownValues, setDropDownValues] = useState<dropdownTypes>({

    selectedEnergyUnit: 'kWh',

    selectedFacility: '',

    selectedFacilityId: '',

  })

  const [enablePopups, setEnableDropDowns] = useState<enablePopupTypes>({

    showUnitPopup: false,

    showFacilityDropDown: false,

    showSettingsFacDropDown: false

  })

  // Show Settings Popup as per the showSettings props

  useEffect(() => {

    if (showSettings) {

      setShowSettingsPopup(true)

    }

  }, [showSettings])





  const [chartOptions, setChartOptions] = useState({});

  const [previousChartOptions, setPreviousChartOptions] = useState<any>([]);

  const chartData = useRef<any>([]);

  const [activeNodeName, setActiveNodeName] = useState('');

  const onChartClick = (params: any) => {

    const clickedNodeName = params.data.name;

    if (clickedNodeName !== undefined && !activeSourceAssets.current.includes(clickedNodeName)) {

      if (chartData.current.filter((item: any) => item['fixedAssetTypeDescFrom'] === clickedNodeName && !activeSourceAssets.current.includes(item.fixedAssetIdFromName)).length !== 0) {

        setActiveNodeName(clickedNodeName);

        const previousChartData = { activeSource: activeSourceAssets.current, data: chartOptions, activeNodeName: clickedNodeName };

        setPreviousChartOptions([...previousChartOptions, previousChartData]);

        convertChartsIntoGroupByType('fixedAssetTypeDescFrom', clickedNodeName, chartData.current);

      } else {

        const toastId = clickedNodeName;

        toast(`No Sub Assets For ${clickedNodeName}`, {toastId})

      }

    }

  }

  const onEvents = {

    click: onChartClick

  }

  const enableBackButtonAndResetChart = () => {

    if (previousChartOptions.length === 1) {

      const previousData = previousChartOptions[0];

      activeSourceAssets.current = previousData.activeSource;

      console.log(previousData, activeSourceAssets.current, "b1");

      let height = 30 + 10 * (previousData.data.series[0].links.length / 5)

      setChartContHeight(height);

      setActiveNodeName(previousData.activeNodeName)

      setChartOptions(previousData.data);

      setPreviousChartOptions([]);

    } else if (previousChartOptions.length > 1) {

      const previousOptions = [...previousChartOptions];

      previousOptions.pop();

      const previousData = previousOptions[previousOptions.length - 1];

      activeSourceAssets.current = previousData.activeSource;

      console.log(previousData, activeSourceAssets.current, "b2");

      setActiveNodeName(previousData.activeNodeName)

      convertChartsIntoGroupByType('fixedAssetTypeDescFrom', previousData.activeNodeName, chartData.current)

      setPreviousChartOptions(previousOptions);

    } else {

      // Handle when there are no previous chart options available

    }

  };

  const activeSourceAssets = useRef<any>([]);

  const chartLinks = useRef<any>({});

  const [chartContHeight, setChartContHeight] = useState(40);

  const convertChartsIntoGroupByType = async (keyName: string, val: string, data: any) => {

    console.log(JSON.stringify(data, null, 2));

    const nodeMap = new Map();

    let links: any = [];

    let contributionObj: any = {};

    let consumptionValObj: any = {};

    let onlySources: any = [];

    const fixedAssetIdFromNames: any = Array.from(new Set(data.map((item: any) => item.fixedAssetIdFromName)));



    console.log(fixedAssetIdFromNames, 'from');



    data.filter((item: any) => item[keyName] === val && !activeSourceAssets.current.includes(item.fixedAssetIdFromName)).forEach((item: any, index: number) => {

      const source = item.fixedAssetIdFromName;

      const target = fixedAssetIdFromNames.includes(item.fixedAssetIdToName) ? item.fixedAssetTypeDescTo : item.fixedAssetIdToName;

      const value = +item.consumption.value;

      const contribution = +item.contribution.value;

      if (contributionObj[source] !== undefined) {

        contributionObj[source] = contributionObj[source] + contribution;

        consumptionValObj[source] = consumptionValObj[source] + value;

      } else {

        contributionObj[source] = contribution;

        consumptionValObj[source] = value;

      }

      if (contributionObj[target] !== undefined) {

        contributionObj[target] = contributionObj[target] + contribution;

        consumptionValObj[target] = consumptionValObj[target] + value;

      } else {

        contributionObj[target] = contribution;

        consumptionValObj[target] = value;

      }

      nodeMap.set(source, item.fixedAssetIdFromName);

      onlySources.push(source);

      nodeMap.set(target, fixedAssetIdFromNames.includes(item.fixedAssetIdToName) ? item.fixedAssetTypeDescTo : item.fixedAssetIdToName);

      links.push({ source, target, value });

    });

    const targets = links.map((ele: { target: any; }) => ele.target)

    let uniqueTargets = Array.from(new Set(targets))

    console.log(links, "links")

    uniqueTargets.forEach((el: any, index: any) => {

      if (index % 2 == 0) {

        links.push({ source: el, target: '', value: 0, lineStyle: { color: 'transparent' } });

      }

    })

    console.log(links, "lin");

    const nodesSet = new Set(nodeMap.values());

    const nodesArray = Array.from(nodesSet).map(name => ({ name }));

    links = links.map((obj: any) => {

      if (obj.value < 0) {

        return { ...obj, value: 28 }

      } else if (obj.value == 0) {

        if (obj.target === '') {

          return { ...obj, value: 0 }

        }

        return { ...obj, value: 43 }

      }

      return { ...obj, value: 61 }

    })

    let height = 30 + 10 * (links.length / 3)

    setChartContHeight(height);

    activeSourceAssets.current = onlySources;

    const sankeyData = { data: nodesArray, links };

    sankeyData.data.push({ name: '' });

    const options = {

      animation: false,

      tooltip: {

        trigger: 'item',

        triggerOn: 'mousemove',

        formatter: function (params: any) {

          // Custom formatting logic for tooltip content

          if (params.dataType === 'node') {

            // Customize tooltip content for nodes (nodes are the items being hovered over)

            const nodeName = params.name;

            let tooltipContent = nodeName;



            // Add your additional changes or modifications to the tooltip content here

            // For example, add consumption and contribution details based on nodeName

            if (consumptionValObj[nodeName] !== undefined && contributionObj[nodeName] !== undefined) {

              tooltipContent += '<br/>' + `Consumption: ${consumptionValObj[nodeName].toFixed(2)} kWh` + '<br/>' + `Contribution: ${contributionObj[nodeName].toFixed(2)}%`;

            }



            return tooltipContent

            //  tooltipContent;

          } else if (params.data.target == '') {

            // Return original tooltip content for other types of data (e.g., links)

            // console.log(params,"sankeysalar")

            return '';

          } else {

            // Return original tooltip content for other types of data (e.g., links)

            let name = params.name.replace(/>/g, '-');

            return name;

          }

        }

      },

      series: [

        {

          type: 'sankey',

          right: 120.0,

          bottom: 25.0,

          height: "80%",

          nodeGap: 28,

          emphasis: {

            focus: 'adjacency'

          },

          data: sankeyData.data,

          links: sankeyData.links,

          label: {

            formatter: (params: any) => {

              if (typeof params.value !== 'undefined') {

                return `{${'black'}|${params.name}}\n${consumptionValObj[params.name] !== undefined ? `{${'val'}|${consumptionValObj[params.name].toFixed(2)} kWh}` : ''}\n${contributionObj[params.name] !== undefined ? `{${'red'}|${`${contributionObj[params.name].toFixed(2)}%`}}` : ''}`;

              } else {

                return params.name;

              }

            },

            position: 'right',

            rich: {

              black: {

                color: 'black',

                fontSize: 12,

                fontWeight:300

              },

              val: {

                color: 'black',

                fontSize: 10.5,

                fontWeight:400

              },

              red: {

                color: 'white',

                fontSize: 10.5,

                backgroundColor: '#29768C',

                width: 'auto',

                padding: [4, 7, 3, 7, 4],

                borderRadius: 3,

              }

            },

            margin: 20,

            width: "120",

            overflow: "truncate",



          },

          lineStyle: {

            color: 'gradient',

            curveness: 0.6

          }

        }

      ],

    }

    console.log('sankeyData::',sankeyData.data , 'SankeyLinks', sankeyData.links );

    chartLinks.current = options;

    setChartOptions(options)

  }



  // Calling Energy Balance API

  const [subEmptyMessage, setSubEmptyMessage] = useState(false);

  const [showDropDownLoader, setDropDownLoader] = useState(false);



  // function to check MAIN_TO_MAIN is available or not...

  const findMainToMainIsAvailableOrNot = async (keyName: string, val: string, data: any) => {

    const nodeMap = new Map();

    let links: any = [];

    let contributionObj: any = {};

    let consumptionValObj: any = {};

    let onlySources: any = [];

    const fixedAssetIdFromNames: any = Array.from(new Set(data.map((item: any) => item.fixedAssetIdFromName)));



    // console.log(fixedAssetIdFromNames, 'from');



    data.filter((item: any) => item[keyName] === val && !activeSourceAssets.current.includes(item.fixedAssetIdFromName)).forEach((item: any, index: number) => {

      const source = item.fixedAssetIdFromName;

      const target = fixedAssetIdFromNames.includes(item.fixedAssetIdToName) ? item.fixedAssetTypeDescTo : item.fixedAssetIdToName;

      const value = +item.consumption.value;

      const contribution = +item.contribution.value;

      if (contributionObj[source] !== undefined) {

        contributionObj[source] = contributionObj[source] + contribution;

        consumptionValObj[source] = consumptionValObj[source] + value;

      } else {

        contributionObj[source] = contribution;

        consumptionValObj[source] = value;

      }

      if (contributionObj[target] !== undefined) {

        contributionObj[target] = contributionObj[target] + contribution;

        consumptionValObj[target] = consumptionValObj[target] + value;

      } else {

        contributionObj[target] = contribution;

        consumptionValObj[target] = value;

      }

      nodeMap.set(source, item.fixedAssetIdFromName);

      onlySources.push(source);

      nodeMap.set(target, fixedAssetIdFromNames.includes(item.fixedAssetIdToName) ? item.fixedAssetTypeDescTo : item.fixedAssetIdToName);

      links.push({ source, target, value });

    });

    const targets = links.map((ele: { target: any; }) => ele.target)

    let uniqueTargets = Array.from(new Set(targets))

    // console.log(links, "links")

    uniqueTargets.forEach((el: any, index: any) => {

      if (index % 2 == 0) {

        links.push({ source: el, target: '', value: 0, lineStyle: { color: 'transparent' } });

      }

    })

    // console.log(links, "lin");

    const nodesSet = new Set(nodeMap.values());

    const nodesArray = Array.from(nodesSet).map(name => ({ name }));

    links = links.map((obj: any) => {

      if (obj.value < 0) {

        return { ...obj, value: 28 }

      } else if (obj.value == 0) {

        if (obj.target === '') {

          return { ...obj, value: 0 }

        }

        return { ...obj, value: 43 }

      }

      return { ...obj, value: 61 }

    })

    let height = 30 + 10 * (links.length / 3)

    // setChartContHeight(height);

    // activeSourceAssets.current = onlySources;

    const sankeyData = { data: nodesArray, links };

    sankeyData.data.push({ name: '' });

    // console.log('line::338::SankeyLinks', sankeyData.links );



    if(sankeyData.links.length !== 0){



      return true;

    }

    return false;



  }







  // Updating API based on the dropDown Values.

  const abortController = useRef(new AbortController());

  useEffect(() => {

    const controller = new AbortController();



    if (dropDownValues.selectedFacilityId) {

      setEnableDropDowns({ showUnitPopup: false, showFacilityDropDown: false, showSettingsFacDropDown: false });

      const callingEnergyBalanceAPI = async (date: any, filterById: any) => {

        setDropDownLoader(true);

        setLoader(true);

        setShowEmptyMessage(false);

        setShowErrorResponse(false);

        try {

          let startDate = moment(date[0], 'YYYY-MM-DD HH:mm:ss').valueOf();

          let endDate = moment(date[1], 'YYYY-MM-DD HH:mm:ss').valueOf();

          let tokenId = localStorage.getItem("@tokenID");

          let options = {

            headers: { Authorization: `Bearer ${tokenId}` },

            signal: controller.signal

          };

          const energyBalanceResp = await fetch(`${API_BASE_URL}energy/consumptionFlow?filterById=${filterById}&endDate=${endDate}&startDate=${startDate}&productMeterTypeId=KWH`, options);



          if (energyBalanceResp.status === 200) {

            let distributionBalanceResp = await energyBalanceResp.json();

            if (distributionBalanceResp.consumptionFlow[0].energySummary.length === 0) {

              setSubEmptyMessage(true);

              setDropDownLoader(false);

              return;

            }

            setDropDownLoader(false);

            setSubEmptyMessage(false);

            activeSourceAssets.current = [];

            setPreviousChartOptions([]);

            setLoader(false);

            let checkForMainToMain = await findMainToMainIsAvailableOrNot('fixedAssetAssocTypeId', 'EB_MAIN_TO_MAIN', distributionBalanceResp.consumptionFlow[0].energySummary);

            // console.log('line:385::',checkForMainToMain)

            if(checkForMainToMain){

              convertChartsIntoGroupByType('fixedAssetAssocTypeId', 'EB_MAIN_TO_MAIN', distributionBalanceResp.consumptionFlow[0].energySummary);

              chartData.current = distributionBalanceResp.consumptionFlow[0].energySummary;

            }else{

              convertChartsIntoGroupByType('fixedAssetAssocTypeId', 'EB_MAIN_TO_SUB', distributionBalanceResp.consumptionFlow[0].energySummary);

              chartData.current = distributionBalanceResp.consumptionFlow[0].energySummary;

            }



            return;

          }

          setShowErrorResponse(true);

          setLoader(false);

          setDropDownLoader(false);

        } catch (error) {

          // setShowErrorResponse(true);

        }

      };



      callingEnergyBalanceAPI(dateRange, dropDownValues.selectedFacilityId);



      // Abort previous controller when the effect runs again or cleans up

      abortController.current.abort();

      abortController.current = controller;

    }



    return () => {

      controller.abort();

    };

  }, [dropDownValues]);



  const [{ dateRange }, dispatch] = useStateValue();

  const [subLoader, setSubLoader] = useState(false);

  useEffect(() => {

    setSubLoader(false);

    setShowEmptyMessage(false);

    setSubEmptyMessage(false);

    let localFacilityData: any = localStorage.getItem("facilityIds")

    let formattedFacilityList = JSON.parse(localFacilityData).map((element: any) => ({ facilityId: element.facilityId, facilityName: element.facilityName }))

    setFacilityList(formattedFacilityList)

    setDropDownValues((element: any) => { return { ...element, selectedFacility: formattedFacilityList[0].facilityName, selectedFacilityId: formattedFacilityList[0].facilityId } })

  }, [dateRange])



  return (

      <>

        <div className="energyDistributionMainContainer">

          <div className="selectorContainer">

            <div className="selectInputDiv">

              <p className="selectedList">{dropDownValues.selectedFacility}</p>

              <img src={ListDownIcon} alt="icon" className="listIcon" onClick={() => setEnableDropDowns((element) => { return { ...element, showFacilityDropDown: !element.showFacilityDropDown } })} />

            </div>

            {enablePopups.showFacilityDropDown && <ul className="selectorList">

              {facilityList.map((ele) => <li key={ele.facilityId} onClick={() => { setSubLoader(true); setDropDownValues((element) => { return { ...element, selectedFacilityId: ele.facilityId, selectedFacility: ele.facilityName } }) }} >{ele.facilityName}</li>)}

            </ul>}

          </div>

          {showDropDownLoader === true ? <div className="loaderStyle"> <LoaderStyle /> </div> : subEmptyMessage ? <DisplayError type={"err-empty"} /> : showErrorResponse ? <DisplayError type={"err-500/404"} />  :

              <> {previousChartOptions.length !== 0 && <div className="backButtonCont">

                <p className="shankiHeaders">{activeNodeName}</p>

                <div className="arrowBtnCont" onClick={() => enableBackButtonAndResetChart()}>

                  <img src={ChartBackIcon} alt="icon" className="backChartIcon" />

                  <p className="backText">Back</p>

                </div>

              </div>}

                <div className="chartContainer" style={{ height: `${chartContHeight}vh` }}>

                  <ReactEcharts option={chartOptions} style={{ width: '90%', transition: 'width 0.5s ease', height: '100%' }} onEvents={onEvents} />

                </div> </>}

          {/* settings Popup code */}

          <Modal centered md show={showSettingsPopup} onHide={() => { setShowSettingsPopup(false); setEnergyDistributionSettingsPopup(false) }} className="energyBalanceSettingsPopup">

            <div className="energyBalancePopupHeader">

              <div className="popupHeaderTextCont">

                <img src={SettingsIcon} alt="icon" className="settingsIcon" />

                <h4 className="energyBalanceHeaderText">Settings</h4>

              </div>

              <div className="closeIconDiv" onClick={() => { setShowSettingsPopup(false); setEnergyDistributionSettingsPopup(false) }} >

                <img src={closeIcon} alt="icon" />

              </div>

            </div>



            <div className="energyBalancePopupBody">

              <div className="selectorContainer">

                <div className="selectOptionContainer">

                  <p className="readingsText">Energy Unit</p>

                  <div className="popupSelectorContainer">

                    <div className="selectInputDiv">

                      <p className="selectedList">{dropDownValues.selectedEnergyUnit}</p>

                      <img src={ListDownIcon} alt="icon" className="listIcon" onClick={() => setEnableDropDowns((element) => { return { ...element, showUnitPopup: !element.showUnitPopup } })} />

                    </div>

                    {enablePopups.showUnitPopup && <ul className="selectorList">

                      {['kWh', 'kVAh'].map((unit) => <li key={unit} onClick={() => { setSubLoader(true); setDropDownValues((element) => { return { ...element, selectedEnergyUnit: unit } }) }} >{unit}</li>)}

                    </ul>}

                  </div>

                </div>

                <div className="selectOptionContainer"

                >

                  <p className="readingsText">Default Facility to Display</p>

                  {showDefaultDropdown ? (<div className="popupSelectorContainer">

                    <div className="selectInputDiv">

                      <p className="selectedList">{dropDownValues.selectedFacility}</p>

                      <img src={ListDownIcon} alt="icon" className="listIcon" onClick={() => setEnableDropDowns((element) => { return { ...element, showSettingsFacDropDown: !element.showSettingsFacDropDown } })} />

                    </div>

                    {enablePopups.showSettingsFacDropDown && <ul className="defaultSelectorList">

                      {facilityList.map((ele) => <li key={ele.facilityId} onClick={() => { setSubLoader(true); setDropDownValues((element) => { return { ...element, selectedFacilityId: ele.facilityId, selectedFacility: ele.facilityName } }) }}>{ele.facilityName}</li>)}

                    </ul>}

                  </div>) : <button className="selectOption" onClick={() => setShowDefaultDropdown(true)}>Select</button>}

                </div>

              </div>

            </div>



            <div className="energyBalancePopupFooter">

              <div className="resetButtonCont">

                <img src={ResetIcon} alt="icon" className="resetIcon" onClick={() => setShowDefaultDropdown(false)} />

                <p className="resetText">Reset to default</p>

              </div>

              <div className="buttonsCont">

                <button className="cancelBtn" onClick={() => { setShowSettingsPopup(false); setEnergyDistributionSettingsPopup(false) }}>Cancel</button>

                <button className="applyBtn">Apply</button>

              </div>

            </div>

          </Modal>

        </div>

      </>

  );

};



export default EnergyDistribution;