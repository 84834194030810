import { useEffect, useState } from "react";
import {AcControllerSetting, AssetCmd} from "../types";

import acControllerArrowIcon from "../../acController/assets/acControllerArrowIcon.svg";
import horiZontalLine from "../../../assets/image/horizontalLine.svg";
import acControllerSuccessIcon from "../../acController/assets/acControllerSuccessIcon.svg";
import { updateAcControllerSettings } from "../service/AcControllerApi";
import { FieldValidatorUtil } from "../../../Utils/fieldValidation/FieldValidatorUtil";
import { useQuery, useQueryClient } from "react-query";
import LocalStorageService from "../../../Utils/LocalStorageService";
import {cancelExistingQuery} from "../../../Utils/utils";

type PropTypes = {
  acControllerSetting: AcControllerSetting;
  assetId: string;
  macId: string;
  assetCmdData: AssetCmd;
};

function TemperatureMode({ acControllerSetting, assetId, macId, assetCmdData, }: PropTypes) {
  const queryClient = useQueryClient();
  const [temperatureModeEnabled, setTemperatureModeEnabled] =
    useState<boolean>(false);

  const [selectedTemp, setSelectedTemp] = useState("");
  const [applyButtonDisabled, setApplyButtonDisabled] = useState(true);

  const [isUpdatePermission, setIsUpdatePermission] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { data, error, isLoading, isFetching, refetch, dataUpdatedAt } =
    useQuery(
      "updateAcControllerSettingsForTemp",
      () =>
        updateAcControllerSettings({
          commandsJson: {
            cmd: assetCmdData.cmdId,
            deviceId: `'${macId}'`,
            tempMode: {
              tempFlag: true,
              setTemp: Number(selectedTemp),
            },
          },
          fixedAssetId: assetId,
          topic: assetCmdData.topic,
        }),
      { enabled: false, keepPreviousData: false }
    );

  const updateSettingInput = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error("Error re-fetching data:", error);
    }
  };

  const handleArrowClicks = () => {
    setTemperatureModeEnabled((prev) => !prev);
  };

  const handleTempValue = (value: string) => {
    if (value.includes(".")) {
      return;
    }
    if (value === "") {
      setSelectedTemp(value);
      setApplyButtonDisabled(false);
      return;
    }
    const regex = /^[+-]?\d+$/;
    const validate = new FieldValidatorUtil();

    if (validate.isAssetAttributeValidate(value, regex)) {
      setSelectedTemp(value);
      const floatValue = parseInt(value);
      if (!isNaN(floatValue)) {
        // Check if the value is within the valid range
        if (floatValue >= 16 && floatValue <= 30) {
          setSelectedTemp(value);
          setApplyButtonDisabled(false);
        } else {
          setApplyButtonDisabled(true);
        }
      }
    } else {
      setApplyButtonDisabled(true);
    }
  };

  const updateUseState = () => {
    const tempValue = acControllerSetting.data.setTemp;
    handleTempValue(tempValue?.toString());
  };

  const checkSecurityPermission = () => {
    const permissions = LocalStorageService.getSecurityPermissionData();
    const status = permissions?.includes("AST_CTRL_UPDATE");
    setIsUpdatePermission(status);
  }

  useEffect(() => {
    if (
      acControllerSetting !== undefined &&
      acControllerSetting.hasOwnProperty("key") &&
      acControllerSetting.key !== ""
    ) {
      updateUseState();
    }
  }, [acControllerSetting]);

  useEffect(() => {
    if (data !== undefined && data === 200) {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
  }, [data, dataUpdatedAt]);

  useEffect(() => {
    cancelExistingQuery("updateAcControllerSettingsForTemp", queryClient);
    checkSecurityPermission();
    return () => {
      setShowSuccessMessage(false);
      cancelExistingQuery("updateAcControllerSettingsForTemp", queryClient);
    };
  }, []);

  return (
    <>
      {/* For Temperature Mode */}
      <div className="bodyContentMainDiv">
        <div
          className="acControllerHeadingDiv"
          onClick={() => handleArrowClicks()}
        >
          <img
            src={acControllerArrowIcon}
            className={`acControllerArrowIcon ${
              temperatureModeEnabled ? "rotate-90" : "rotate-0"
            }`}
          />
          <p className="acControllerHeadingText">Temperature Mode</p>
        </div>
        {temperatureModeEnabled && (
          <>
            <div className="acControllerDataInputDiv">
              <div className="acControllerDataDiv">
                <p className="acControllerCategory">Actual set point</p>
                <div className="tempInputDiv">
                  <input
                    className="temperatureInput"
                    type="text"
                    value={selectedTemp}
                    onChange={(e) => handleTempValue(e.target.value)}
                    readOnly={!isUpdatePermission}
                  />
                  <img className="horizontalImage" src={horiZontalLine} />
                  <p className="tempUnit">°C</p>
                </div>
                {applyButtonDisabled && selectedTemp !== "" && (
                  <p className="tempErrorMessage">
                    Enter a value between 16°C to 30°C
                  </p>
                )}
              </div>
            </div>
            { isUpdatePermission && (
                  <>
                    {showSuccessMessage ? (
                        <div className="successDivCont">
                          <img src={acControllerSuccessIcon} alt="success-icon" />
                          <p className="successText"> Success</p>
                        </div>
                    ) : isLoading || isFetching ? (
                        <div className="loading-container">
                          <div className="loading-spinner"></div>
                        </div>
                    ) : (
                        <div
                            className="applyButtonDiv"
                            onClick={() => {
                              if (selectedTemp !== "" && !applyButtonDisabled) {
                                updateSettingInput();
                              }
                            }}
                            style={{
                              opacity: selectedTemp === "" || applyButtonDisabled ? 0.3 : 1,
                              cursor:
                                  selectedTemp === "" || applyButtonDisabled
                                      ? "not-allowed"
                                      : "pointer",
                            }}
                        >
                          <p className="applyButtonText">
                            {error || (data !== undefined && data !== 200)
                                ? "Retry"
                                : "Apply"}
                          </p>
                        </div>
                    )}
                  </>
                )
            }
          </>
        )}
      </div>
    </>
  );
}

export default TemperatureMode;
