import React from 'react'
import './NewAssetDetailComponent.scss'
import dummyMeterIcon from '../../assets/icons/dummuMeterIcon.svg'
import { validateNullValues, validatePowerFactor } from './AssetDetailsUtils'

function LiveMeterCard({metersDataArr, checkThresHoldValue, el, fullSize}) {
    return (
        <div style={{ width: (fullSize !== undefined && fullSize === true) ? metersDataArr.length === 1 ? 'auto' : '48%' : 'auto' }} key={el.name} className='newAssetMeterDetailsCard'>
            <p>{el.name}</p>
            <div className='meterDetailsIocnAndLabelContainer'>

                {el.imgUrl !== null && <div className='meterDetailsReUseIconContainer'>
                    <img src={el.imgUrl} />
                </div>}

                {/* flex-wrap: wrap !important;
                                    justify-content: flex-start !important; */}

                <div className='meterDetailsCardDymanicLabelsAndValuesConatiner' style={{ width: (fullSize !== undefined && fullSize === true) ? '100%': '' , justifyContent : (fullSize !== undefined && fullSize === true) ? 'flex-start': '', flexWrap: (fullSize !== undefined && fullSize === true) ? 'wrap': 'nowrap' }}   >
                    {el.options.sort((a,b)=> a.sequenceNo - b.sequenceNo).map((ele) => <div key={ele.name} className='meterDetailsCardDymanicLabelsAndValuesCardConatiner'>
                        <p className='meterReadingLabelFont'> {el.name === 'Meter reading' ? `${ele.name}` : ele.name}</p>
                        <h3 className='meterReadingValue' style={{color:checkThresHoldValue(el.name, 'color')}} > {el.name === 'Power factor' ? validatePowerFactor(ele.value, ele.readingUomDesc, ele.decimalControl) : validateNullValues(ele.value, ele.readingUomDesc, ele.decimalControl)} </h3>
                        {checkThresHoldValue(el.name,'content').length !== 0 && <p className='minMaxFontText'>
                                                    {
                                                        checkThresHoldValue(el.name,'content').length == 3 ?
                                                        <><span>Min</span> {checkThresHoldValue(el.name,'content')[0]} { checkThresHoldValue(el.name,'content')[2]} - <span>Max</span> {checkThresHoldValue(el.name,'content')[1]} {checkThresHoldValue(el.name,'content')[2]}</> : 
                                                        <><span>Limit </span>{checkThresHoldValue(el.name,'content')[0]} { checkThresHoldValue(el.name,'content')[1]} </>
                                                    }      
                                                    </p>}
                    </div>)}
                </div>
            </div>
        </div>
    )
}

export default LiveMeterCard