import "./EventListCard.scss";

import TempIcon from "../assets/icon-temperature.svg";
import MaskIcon from "../assets/icon_mask.svg";
import EventDefaultIcon from "../assets/icon-events-default.svg";
import ClockIcon from "../assets/ic_clock.svg";
import ImageIcon from "../assets/ic_image.svg";
import VideoIcon from "../assets/ic_video.svg";
import MarkerIcon from "../assets/ic_marker.svg";
import EventListDetail from "./EventListDetail";
import moment from "moment";
import OnGoingEvent from "../assets/on-going-icon.svg";
import Modal from "react-bootstrap/Modal";
import { EventLog, MediaListType } from "../types/EventListTypes";
import EventListState from "../state/EventListState";
import { formatDuration } from "../utils/Utils";
import { useEffect, useState } from "react";
import MediaCarousel from "./MediaCarousel";
import { getAreaDetails, getFacilityDetails } from "../utils";

type PropsType = {
  data: EventLog;
  index: number;
  handleMouseEnter: (index: number) => void;
  handleMouseLeave: () => void;
  hoveredIndex: null | number;
};

function EventListCard({
  data,
  index,
  handleMouseEnter,
  handleMouseLeave,
  hoveredIndex,
}: PropsType) {
  const { showDetails, setShowDetails } = EventListState();

  const [showMediaCarousel, setShowMediaCarousel] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [mediaList, setMediaList] = useState<MediaListType[]>([]);
  const [facilityData, setFacilityData] = useState<any>({});
  const [areaData, setAreaData] = useState<any>({});

  let formattedDuration = formatDuration(data.startDateTime, data.endDateTime);

  const showMediaCarouselPopUp = (data: MediaListType[], index: number) => {
    setCarouselIndex(index);
    setMediaList(data);
    setShowMediaCarousel(true);
  };

  useEffect(() => {
    const extractData = async () => {
     try{
      const facility = await getFacilityDetails(data.locatedAtFacilityId);
      setFacilityData(facility);
       const area = await getAreaDetails(data.locatedAtFacilityId);
       setAreaData(area);
        }
     catch(error){
      console.error(error)
     }
    };
    extractData();
  }, [data]);

  return (
    <>
      {showDetails && (
        <Modal
          size="lg"
          show={showDetails}
          onHide={() => setShowDetails(false)}
          centered
          backdrop="static"
          className="eventListDetailPopUp"
        >
          <EventListDetail
            show={showDetails}
            onHide={() => setShowDetails(false)}
            eventId={data.eventId}
            startDateTime={data.startDateTime}
            assetId={data.assetId}
            objectCount={data.objectCount}
            showMediaCarouselPopUp={showMediaCarouselPopUp}
          />
        </Modal>
      )}

      {showMediaCarousel && (
        <MediaCarousel
          data={mediaList}
          show={showMediaCarousel}
          carouselIndex={carouselIndex}
          onHide={() => setShowMediaCarousel(false)}
        />
      )}

      <div
        className="eventListCard"
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
        style={{
          borderColor:
            hoveredIndex === index
              ? "rgba(38, 128, 235, 1)"
              : "rgba(228, 228, 228, 1)",
        }}
        onClick={() => setShowDetails(true)}
      >
        <div className="cardBody">
          <div className="cardTitleDiv">
            <div className="eventTitleAndIcon">
              <img
                className="eventIcon"
                src={
                  data.parentNoteTypeId === "FIRE_HAZARD"
                    ? TempIcon
                    : data.parentNoteTypeId === "SAFETY"
                    ? MaskIcon
                    : EventDefaultIcon
                }
              />
              <p className="eventTitle">{data.eventDescription}</p>
            </div>
            {data.endDateTime!==null?(
            <p className="eventLastOccured">
              {moment(data.startDateTime).fromNow()}
              </p>) : (
                 <div className="onGoingMainDiv">
                 <img src={OnGoingEvent} className="blinkingImage" />
                 <p className="onGoingText">Ongoing</p>
               </div>
            )}
          </div>
          <div className="eventCategoryMainDiv">
            <div className="eventCategoryDiv">
              <p className="eventCategoryName">{data.eventCategoryDesc}</p>
              <p className="eventDescName">{data.eventTypeDesc}</p>
            </div>
            {data.endDateTime !== null ? (
              <div className="eventLastTimestamp">
                <img src={ClockIcon} />
                <p className="lastTimeStampText">{formattedDuration}</p>
              </div>
            ) : (
              <p className="eventLastOccured">
              {moment(data.startDateTime).fromNow()}
              </p>
            )}
          </div>
        </div>
        <div className="cardFooter">
          <div className="eventAssetCount">
            <div className="eventOccuredCount">
              <p className="eventOccuredCountValue">#{data.objectCount}</p>
              <p className="eventOccuredCountText">Count</p>
            </div>
            <div className="assetDiv">
              <img src={ImageIcon} />
              <p className="assetCountText">{data.imageCount}</p>
            </div>
            <div className="assetDiv">
              <img src={VideoIcon} />
              <p className="assetCountText">{data.videoCount}</p>
            </div>
          </div>
          <div className="eventFacilityDetails">
            <img src={MarkerIcon} />
            <p className="facilityDetails">
              {facilityData.facilityName}, {areaData.locationName}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventListCard;
