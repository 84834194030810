import React, {useEffect, useState} from "react";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import {useQuery, useQueryClient} from "react-query";
import {getAcControllerSetting, getAssetCmdList} from "../service/AcControllerApi";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import TimerMode from "./TimerMode";
import {AcControllerUtils} from "../utils/AcControllerUtils";
import {AcControllerSetting, AcControllerSettingItem, AssetCmd, AssetCmdListType} from "../types";
import TemperatureMode from "./TemperatureMode";
import UiMode from "./UiMode";
import {cancelExistingQuery} from "../../../Utils/utils";
import {ControllerId} from "../../../Utils/enumData";
import {settingInitialValue} from "../utils/controllerConstant";

type PropsType = {
    assetId: string;
    macId: string;
};

const AcControllerSummaryCard = ({assetId, macId}: PropsType) => {
    const queryClient = useQueryClient();

    const [acControllerSettingItem, setAcControllerSettingItem] =
        useState<AcControllerSettingItem>(settingInitialValue);

    const {data, error, isLoading, isFetching, refetch, dataUpdatedAt} = useQuery(
        "getAcControllerSettingApi",
        () => getAcControllerSetting(assetId, macId),
        {
            enabled: false,
            keepPreviousData: false,
        }
    );

    const {
        data: assetCmdListData,
        error: assetCmdListError,
        isLoading: assetCmdListLoading,
        isFetching: assetCmdListFetching,
        refetch: refetchAssetCmdList,
        dataUpdatedAt: assetCmdListDataUpdatedAt
    } = useQuery<AssetCmdListType | undefined>(
        "getAssetCmdListApi",
        () => getAssetCmdList(assetId),
        {
            enabled: false,
            keepPreviousData: false,
        }
    );

    const fetchDataAndUpdateState = async () => {
        try {
            await refetch();
            await refetchAssetCmdList();
        } catch (error) {
            console.error("Error re-fetching data:", error);
        }
    };

    const filterSettingControl = () => {
        if (data?.assetCommand.hasOwnProperty("fixedAssetId")) {
            const utils = new AcControllerUtils();
            const result = utils.extractAcControllerSetting(data?.assetCommand);
            const settingItem: any = {};
            result.forEach((item) => {
                if (item.key === "1000") {
                    settingItem.opMode = item;
                } else if (item.key === "202") {
                    settingItem.timerMode = item;
                } else if (item.key === "203") {
                    settingItem.tempMode = item;
                }
            });
            setAcControllerSettingItem(settingItem);
        }
    };

    const checkIsInputTypeVisible = (id: string) => {
        return assetCmdListData?.assetCmds.some((item: AssetCmd) => item.cmdId === id) || false;
    }

    const filterAssetCmdData = (id: string) => {
        return assetCmdListData?.assetCmds.find((item: AssetCmd) => item.cmdId === id);
    }

    const renderControllerMode = (id: string, Component: React.FC<any>, settings: AcControllerSetting) => {
        const assetCmdData = filterAssetCmdData(id);
        return checkIsInputTypeVisible(id) && assetCmdData !== undefined && (
            <Component
                acControllerSetting={settings}
                assetId={assetId}
                macId={macId}
                key={JSON.stringify(settings)}
                assetCmdData={assetCmdData}
            />
        );
    }

    useEffect(() => {
        fetchDataAndUpdateState();
    }, [assetId, macId]);

    useEffect(() => {
        if (data !== undefined) {
            filterSettingControl();
        }
    }, [data, dataUpdatedAt]);

    useEffect(() => {
        return (() => {
            setAcControllerSettingItem(settingInitialValue);
            cancelExistingQuery("getAcControllerSettingApi", queryClient);
            cancelExistingQuery("getAssetCmdListApi", queryClient);
        })
    }, []);

    if (assetCmdListLoading || assetCmdListFetching) {
        return <LoaderStyle/>;
    }

    if (typeof assetCmdListData === "object" &&
        !(assetCmdListData?.hasOwnProperty("assetCmdCount") &&
            assetCmdListData?.hasOwnProperty("assetCmds"))) {
        return <DisplayError type="err-500/404"/>;
    }

    if (assetCmdListError || typeof assetCmdListData === "number") {
        return <DisplayError type={"err_1"}/>;
    }

    if (assetCmdListData && assetCmdListData?.assetCmds?.length === 0) {
        return <DisplayError type="err-empty"/>;
    }

    return (
        <div className="acControllerContainerDiv">
            {renderControllerMode(ControllerId.TIMER, TimerMode, acControllerSettingItem.timerMode)}
            {renderControllerMode(ControllerId.TEMPERATURE, TemperatureMode, acControllerSettingItem.tempMode)}
            {renderControllerMode(ControllerId.UI_MODE, UiMode, acControllerSettingItem.opMode)}
        </div>
    );
};

export default AcControllerSummaryCard;
