import React, { useEffect } from "react";
import acControllerArrowIcon from "../../acController/assets/acControllerArrowIcon.svg";
import ToggleSwitch from "./ToggleSwitch";
import { useQuery, useQueryClient } from "react-query";
import {
  getAssetAckCommand,
  updateAcControllerSettings,
} from "../service/AcControllerApi";
import acControllerSuccessIcon from "../../acController/assets/acControllerSuccessIcon.svg";
import useState from "react-usestateref";
import moment from "moment";
import {AcControllerSetting, AssetCmd} from "../types";
import LocalStorageService from "../../../Utils/LocalStorageService";

type PropTypes = {
  assetId: string;
  macId: string;
  acControllerSetting: AcControllerSetting;
  assetCmdData: AssetCmd;
};

function UiMode({ assetId, macId, acControllerSetting, assetCmdData, }: PropTypes) {
  const queryClient = useQueryClient();

  const [uiModeEnabled, setUiModeEnabled] = useState(false);
  const [uiModeState, setUiModeState] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [currentTimeStamp, setCurrentTimeStamp, currentTimeStampRef] =
    useState(0);
  const [timeoutVal, setTimeoutVal, timeoutValRef] = useState<any>(null);
  const [timeRemainingVal, setTimeRemainingVal, timeRemainingValRef] =
    useState<any>(null);
  const [currentPollingIndex, setCurrentPollingIndex, currentPollingIndexRef] =
    useState(0);
  const [lastUiModeState, setLastUiModeState] = useState(false);
  const [apiCallState, setApiCallState, apiCallStateRef] = useState({
    buttonText: "Apply",
    timeRemaining: 15,
    state: "",
  });

  const [isUpdatePermission, setIsUpdatePermission] = useState(false);

  const acknowledgementTime = Number(assetCmdData?.ackTime);
  const defaultPollInterval = 0;
  const pollInterval = assetCmdData?.ack === "Y" ?
      (assetCmdData.hasOwnProperty("ackTime") && !Number.isNaN(acknowledgementTime)) ?
          acknowledgementTime : defaultPollInterval : defaultPollInterval;
  const pollIntervalCount = pollInterval / 5;

  const { data, error, isLoading, isFetching, refetch, dataUpdatedAt } =
    useQuery(
      "updateAcControllerSettingsForUiMode",
      () =>
        updateAcControllerSettings({
          commandsJson: {
            cmd: assetCmdData.cmdId,
            deviceId: `'${macId}'`,
            trigger: {
              opMode: true,
              state: uiModeState,
            },
          },
          fixedAssetId: assetId,
          topic: assetCmdData.topic,
        }),
      {
        enabled: false,
        keepPreviousData: false,
      }
    );

  const {
    data: ackData,
    error: ackError,
    isLoading: ackIsLoading,
    isFetching: ackIsFetching,
    refetch: ackRefetch,
    dataUpdatedAt: ackDataUpdatedAt,
  } = useQuery(
    "getAssetAckCommandUiMode",
    () => getAssetAckCommand(macId, assetId, currentTimeStampRef.current),
    {
      enabled: false,
      keepPreviousData: false,
    }
  );

  const handleArrowClicks = () => {
    setUiModeEnabled((prev) => !prev);
  };

  /**
   * Call the Triggred Api
   */
  const updateUiMode = async () => {
    cancelExistingQuery("updateAcControllerSettingsForUiMode");
    const temp = {
      ...apiCallStateRef.current,
      state: "triggredApiLoading",
    };
    setApiCallState(temp);

    const currentTimestamp = moment()
      .valueOf();
    setCurrentTimeStamp(currentTimestamp);

    try {
      await refetch();
    } catch (error) {
      const temp = {
        ...apiCallStateRef.current,
        state: "",
        buttonText: "Retry",
      };
      setApiCallState(temp);
      console.error("Error re-fetching data:", error);
    }
  };

  /**
   * Fetch the Acknowledment Api
   */
  const fetchAckCommand = async () => {
    if (currentPollingIndexRef.current === pollIntervalCount) {
      const temp = {
        ...apiCallStateRef.current,
        state: "",
        buttonText: "Retry",
      };
      setApiCallState(temp);
      setUiModeState(lastUiModeState);
      clearPolling();
    } else {
      setCurrentPollingIndex((prev) => prev + 1);
      try {
        await ackRefetch();
      } catch (error) {
        const temp = {
          ...apiCallStateRef.current,
          state: "",
          buttonText: "Retry",
        };
        setApiCallState(temp);
        console.error("Error re-fetching for Ack command:", error);
      }
    }
  };

  const clearPolling = () => {
    setCurrentPollingIndex(0);
    setCurrentTimeStamp(0);
    clearInterval(timeoutValRef.current);
    clearInterval(timeRemainingValRef.current);
    cancelExistingQuery("getAssetAckCommandUiMode");
    const temp = {
      ...apiCallStateRef.current,
      timeRemaining: pollInterval,
    };
    setApiCallState(temp);
  };

  const updateRemainingTimeForAck = () => {
    const interval = setInterval(() => {
      const temp = {
        ...apiCallStateRef.current,
        timeRemaining: apiCallStateRef.current.timeRemaining - 1,
      };
      setApiCallState(temp);
    }, 1 * 1000);
    setTimeRemainingVal(interval);
  };

  const checkSecurityPermission = () => {
    const permissions = LocalStorageService.getSecurityPermissionData();
    const status = permissions?.includes("AST_CTRL_UPDATE");
    setIsUpdatePermission(status);
  }

  const poolAckCommand = () => {
    const temp = {
      ...apiCallStateRef.current,
      state: "ackCmdApiLoading",
      timeRemaining: pollInterval,
    };
    setApiCallState(temp);
    updateRemainingTimeForAck();
    fetchAckCommand();
    const interval = setInterval(() => {
      fetchAckCommand();
    }, 5 * 1000);
    setTimeoutVal(interval);
  };

  useEffect(() => {
    if (data !== undefined && data === 200) {
      if(pollIntervalCount === 0){
        const temp = {
          ...apiCallStateRef.current,
          state: "success",
        };
        setApiCallState(temp);
        setTimeout(() => {
          const temp = {
            ...apiCallStateRef.current,
            state: "",
          };
          setApiCallState(temp);
        }, 3000);
      }else {
        poolAckCommand();
      }
    }
  }, [data, dataUpdatedAt]);

  useEffect(() => {
    if (ackData !== undefined && ackData.hasOwnProperty("triggerAck")) {
      clearPolling();
      let triggerAck = ackData?.triggerAck;
      triggerAck = JSON.parse(triggerAck);
      setUiModeState(triggerAck?.STATE);
      setLastUiModeState(triggerAck?.STATE);
      const temp = {
        ...apiCallStateRef.current,
        state: "success",
      };
      setApiCallState(temp);
      setTimeout(() => {
        const temp = {
          ...apiCallStateRef.current,
          state: "",
        };
        setApiCallState(temp);
      }, 3000);
    }
  }, [ackData, dataUpdatedAt]);

  /**
   * It will remove the cache of the react-query call for the specific id.
   * @param id : id for the react-query call
   */
  const cancelExistingQuery = (id: string) => {
    queryClient
      .getQueryCache()
      .getAll()
      .forEach((query) => {
        if (query.queryKey.includes(id)) {
          queryClient.removeQueries(query.queryKey);
        }
      });
  };

  const updateUseState = () => {
    const value = acControllerSetting.data.state;
    setUiModeState(value);
   setLastUiModeState(value)
  };

  useEffect(() => {
    if (
      acControllerSetting !== undefined &&
      acControllerSetting.hasOwnProperty("key") &&
      acControllerSetting.key !== ""
    ) {
      updateUseState();
    }
  }, [acControllerSetting]);

  useEffect(() => {
    cancelExistingQuery("updateAcControllerSettingsForUiMode");
    cancelExistingQuery("getAssetAckCommandUiMode");
    checkSecurityPermission();
    return () => {
      setShowSuccessMessage(false);
      clearPolling();
      cancelExistingQuery("updateAcControllerSettingsForUiMode");
      cancelExistingQuery("getAssetAckCommandUiMode");
    };
  }, []);

  useEffect(() => {
    if (error !== null) {
      const temp = {
        ...apiCallStateRef.current,
        state: "triggredApiFail",
      };
      setApiCallState(temp);
    }
  }, [error, dataUpdatedAt]);


  return (
    <>
      {/* For UI Mode */}
      <div className="bodyContentMainDiv">
        <div
          className="acControllerHeadingDiv"
          onClick={() => handleArrowClicks()}
        >
          <img
            src={acControllerArrowIcon}
            className={`acControllerArrowIcon ${
              uiModeEnabled ? "rotate-90" : "rotate-0"
            }`}
          />
          <p className="acControllerHeadingText">UI Mode</p>
        </div>
        {uiModeEnabled && (
          <>
            <div className="acControllerDataInputDiv">
              <div className="acControllerDataDiv">
                <ToggleSwitch
                  uiModeState={uiModeState}
                  updateUiMode={() => setUiModeState((prev) => !prev)}
                  updatePermission={isUpdatePermission}
                />
              </div>
            </div>
            {
              isUpdatePermission && (
                  <>
                    {apiCallStateRef.current.state === "" ? (
                        <div className="applyButtonDiv" onClick={updateUiMode}>
                          <p className="applyButtonText">
                            {apiCallStateRef.current.buttonText}
                          </p>
                        </div>
                    ) : apiCallStateRef.current.state === "triggredApiLoading" ||
                    apiCallStateRef.current.state === "ackCmdApiLoading" ? (
                        <div className="loading-container">
                          <div className="loading-spinner"></div>
                          {apiCallStateRef.current.state === "ackCmdApiLoading" && (
                              <p className="acknowledgementWaitText">
                                Awaiting acknowledgement{" "}
                                {apiCallStateRef.current.timeRemaining}s
                              </p>
                          )}
                        </div>
                    ) : apiCallStateRef.current.state === "success" ? (
                        <div className="successDivCont">
                          <img src={acControllerSuccessIcon} alt="success-icon" />
                          <p className="successText"> Success</p>
                        </div>
                    ) : (
                        <></>
                    )}
                  </>
                )
            }
          </>
        )}
      </div>
    </>
  );
}

export default UiMode;
