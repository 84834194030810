export const settingInitialValue = {
    timerMode: {
        key: "",
        data: {
            state: undefined,
            opMode: undefined,
            onTimeHour: undefined,
            onTimeMin: undefined,
            offTimeHour: undefined,
            dayOfWeek: undefined,
            timerFlag: undefined,
            offTimeMin: undefined,
            setTemp: undefined,
            macId: undefined,
            tempFlag: undefined,
        },
        latestUpdate: 0,
    },
    tempMode: {
        key: "",
        data: {
            state: undefined,
            opMode: undefined,
            onTimeHour: undefined,
            onTimeMin: undefined,
            offTimeHour: undefined,
            dayOfWeek: undefined,
            timerFlag: undefined,
            offTimeMin: undefined,
            setTemp: undefined,
            macId: undefined,
            tempFlag: undefined,
        },
        latestUpdate: 0,
    },
    opMode: {
        key: "",
        data: {
            state: undefined,
            opMode: undefined,
            onTimeHour: undefined,
            onTimeMin: undefined,
            offTimeHour: undefined,
            dayOfWeek: undefined,
            timerFlag: undefined,
            offTimeMin: undefined,
            setTemp: undefined,
            macId: undefined,
            tempFlag: undefined,
        },
        latestUpdate: 0,
    },
}