import axios from 'axios';
import { addAuthTokenToHeaders } from './authInterceptorHelper';

export function setAuthTokenInterceptor() {
    console.log("Interceptor initialized");

    // Axios interceptor
    axios.interceptors.request.use(
        config => {
            config.headers = addAuthTokenToHeaders(config.headers);
            console.log("Token added to Axios request headers");
            return config;
        },
        error => {
            console.error("Axios Interceptor Error:", error);
            return Promise.reject(error);
        }
    );

    // Fetch interceptor
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
        let [resource, config] = args;

        let response;
        if(config !== undefined){
            config.headers = addAuthTokenToHeaders(config.headers);
            response = await originalFetch(resource, config);
        } else {
            response = await originalFetch(resource, config);
        }

        // response interceptor
        const json = () =>
            response
                .clone()
                .json()
                .then((data) => ({ ...data, title: `Intercepted: ${data.title}` }));

        response.json = json;
        return response;
    };
}
