import "./AcControllerCard.scss";
import { Offcanvas } from "react-bootstrap";
import AcControllerIcon from "../../acController/assets/acControllerIcon.svg";
import CloseIcon from "../../acController/assets/closeButton.svg";
import AcControllerSummaryCard from "./AcControllerSummaryCard";

type PropTypes = {
  show: boolean;
  onClose: () => void;
  assetId: string;
  macId: string;
};

function AcControllerCard({ show, onClose, assetId, macId }: PropTypes) {
  return (
    <>
      <Offcanvas show={show} onHide={onClose} placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className="acControllerModalmainContainerHeader">
              <div className="acControllerModalmainContainerHeaderDetails">
                <img
                  src={AcControllerIcon}
                  className="acControllerIcons"
                  alt="Ac Controller Icon"
                />
                <div className="acControllerModalHeader">
                  <p className="acControllerModalHeaderTitle">Controls</p>
                  <p className="acControllerModalHeaderSubTitle">
                    Manage your assets remotely
                  </p>
                </div>
              </div>
              <div className="closeIconDiv" onClick={onClose}>
                <img src={CloseIcon} className="closeIcon" alt="Close Icon" />
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AcControllerSummaryCard assetId={assetId} macId={macId} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default AcControllerCard;
