import { Link } from "react-router-dom";
import CostIcon from "../../assets/icons/costIcon.svg";
import EnergyConsumptionIcon from "../../assets/icons/energyConsumptionIcon.svg";
import EnergyIcon from "../../assets/icons/energyIcon.svg";
import EnergyVoltsIcon from "../../assets/icons/energyVoltsIcon.svg";
import EstimatedCostIcon from "../../assets/icons/estimatedCostIcon.svg";
import FilterIcon from "../../assets/icons/filterArrowIcon.svg";
import InActiveMeterIcon from "../../assets/icons/metersInActiveIcon.svg";
import "./OldEnergyConsumption.scss";
import MeterCard from "./meterCard/MeterCard";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import { useStateValue } from "../../redux/StateProvider";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import ApparentPowerIcon from "../../assets/icons/avgApparentPowerIcon.svg";
import AvgConsumptionIcon from "../../assets/icons/avgConsumptionIcon.svg";
import AvgPowerFactorIcon from "../../assets/icons/avgPowerFactorIcon.svg";
import ReactEcharts from "echarts-for-react";
import LoaderStyle from "../LoaderStyle/LoaderStyle";
import DisplayError from "../DisplayError/DisplayError";
import {roundToDecimalNumber} from "../../Utils/utils";
import AverageReactPowerIcon from '../../assets/icons/AverageReactivePowerEC.svg'
import * as R from "ramda";

function SingleFacilityEnergyConsumption() {
  const [singleFacilityData, setSingleFacilityData] = useState([]);
  const [{ dateRange, dateChip }, dispatch] = useStateValue();
  const [chartOptions, setChartOptions] = useState({});
  const [meterOptions, setMeterOptions] = useState({});
  const [activeMeter, setActiveMeter] = useState("");
  const [meterAssetData, setMeterAssetData] = useState<any>({
    averageConsumptionPerDay: '',
    abbreviation: '', powerFactor: ''
  });
  const [apiCallType, setAPICallType] = useState("Main");
  const [mainRespStat, setMainRespStat] = useState("load");
  const [dropDownRespStat, setDropdownRespStat] = useState("resp");

  const formatChartData = () => {
    const xAxisData = singleFacilityData?.data?.map(
      (item: any) => item.dateValue
    );
    const energyConsumptionData = singleFacilityData?.data?.map((item: any) =>
      parseFloat(item.energyConsumption).toFixed(0)
    );
    const estimatedCostData = singleFacilityData?.data?.map((item: any) =>
      parseFloat(item.estimatedCost).toFixed(0)
    );
    if (activeMeter === "") {
      setChartOptions({
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let tooltipText = params[0].axisValueLabel + '<br>';
            params.forEach(param => {
              tooltipText += `${param.marker} ${param.seriesName}: ${parseFloat(param.data).toLocaleString("en-IN")}<br>`;
            });
            return tooltipText;
          }
        },
        xAxis: {
          type: "category",
          data: xAxisData,
        },
        yAxis: [
          {
            type: "value",
            name: "Energy Consumption (kWh)",
            position: 'right',
            min: 0,
            max: (Math.max(...energyConsumptionData) * 1.0).toFixed(0),
            nameRotate: 90,
            nameLocation: 'middle',
            nameGap: 55,
            axisLabel: {
              formatter: function (value) {
                return value.toLocaleString("en-IN");
              }
            }
          },
          {
            type: "value",
            name: "Estimated Cost (INR)",
            position: 'left',
            min: 0,
            max: (Math.max(...estimatedCostData) * 1.0).toFixed(0),
            nameRotate: 90,
            nameLocation: 'middle',
            nameGap: 65,
            axisLabel: {
              formatter: function (value) {
                return value.toLocaleString("en-IN");
              }
            }
          },
        ],
        series: [
          {
            name: "Energy Consumption (kWh)",
            type: "bar",
            data: energyConsumptionData,
            yAxisIndex: 0,
            barMaxWidth: 40,
          },
          {
            name: "Estimated Cost (INR)",
            type: "line",
            data: estimatedCostData,
            yAxisIndex: 1,
            barMaxWidth: 40,
          },
        ],
        grid: {
          left: "3%",
          right: "5%",
          containLabel: true,
        },
      });
      return;
    }

    const meterData = singleFacilityData?.assetdetails.filter(
      (obj: any) => obj.assets.fixedAssetId == activeMeter
    );
    console.log(meterData, "single");
    setMeterAssetData(meterData[0].assets);
    const meterEnergyConsumption = meterData[0].trendAssetData.map(
      (data: any) => parseFloat(data.energyConsumption).toFixed(0)
    );
    const meterDatesValue = meterData[0].trendAssetData.map(
      (data: any) => data.dateValue
    );

    setMeterOptions({
      tooltip: {
        trigger: "axis",
        formatter: function (params) {
          let tooltipText = params[0].axisValueLabel + '<br>';
          params.forEach(param => {
            tooltipText += `${param.marker} ${param.seriesName}: ${parseFloat(param.data).toLocaleString("en-IN")}<br>`;
          });
          return tooltipText;
        }
      },
      xAxis: {
        type: "category",
        data: meterDatesValue,
      },
      yAxis: [
        {
          type: "value",
          name: "Energy Consumption (kWh)",
          min: 0,
          max: (Math.max(...meterEnergyConsumption) * 1.0).toFixed(0),
          nameRotate: 90,
          nameLocation: 'middle',
          nameGap: 55,
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString("en-IN");
            }
          }
        },
      ],
      series: [
        {
          name: "Energy Consumption (kWh)",
          type: "bar",
          data: meterEnergyConsumption,
          yAxisIndex: 0,
          barMaxWidth: 40,
        },
      ],
      grid: {
        left: "3%",
        right: "2%",
        containLabel: true,
      },
    });
  };

  const changeMeterId = (meterId: any) => {
    if (meterId === activeMeter) {
      setActiveMeter("");
      return;
    }
    setActiveMeter(meterId);
  };

  useEffect(() => {
    if (singleFacilityData?.data) {
      formatChartData();
    }
  }, [singleFacilityData, activeMeter]);
  type ChartsDropDownData = {
    [key: string]: string[];
  };
  const chartsDropDownData: ChartsDropDownData = {
    Today: [
      "Last 7 days",
      "Last 14 Days",
      "Last 30 Days",
      "Last 60 Days",
      "Last 90 Days",
    ],
    "This Week": ["Last 4 Weeks", "Last 8 Weeks", "Last 12 Weeks"],
    "This Month": [
      "Last 3 Months",
      "Last 6 Months",
      "Last 9 Months",
      "Last 12 Months",
    ],
    customDate: ["Custom Range"],
  };
  const [dropDownDisplayType, setDropDownDisplayType] = useState<string>("");
  const [dropDownList, setDropDownList] = useState([""]);
  const [showDropDown, showDropDownList] = useState(false);
  useEffect(() => {
    setAPICallType("Main");
    if (Object.keys(chartsDropDownData).includes(dateChip)) {
      setDropDownDisplayType(chartsDropDownData[dateChip][0]);
      setDropDownList(chartsDropDownData[dateChip]);
      return;
    }
    setDropDownDisplayType(chartsDropDownData["customDate"][0]);
    setDropDownList(chartsDropDownData["customDate"]);
  }, [dateRange, dateChip]);

  const getSingleFacilityData = async (
    date: any,
    type: string,
    facId: string,
    dateType: string,
    abortController :any
  ) => {
    let startDate = moment(date[0], "YYYY-MM-DD HH:mm:ss").valueOf();
    let endDate = moment(date[1], "YYYY-MM-DD HH:mm:ss").valueOf();
    const token: any = localStorage.getItem("@tokenID");
    const options = {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
      signal: abortController.signal
    };
    const params = {
      options: options,
      startDate: startDate,
      endDate: endDate,
      facilityType: type,
      filterById: facId,
      dateType: dateType,
    };
    if (apiCallType == "Main") {
      setMainRespStat("load");
    } else {
      setDropdownRespStat("load");
    }

    const singleFacilityManagerResp =
      await new DashBoardServices().oldEnergyFacilityManagerData(params);
    setActiveMeter('')
    if (apiCallType == "Main") {
      if (singleFacilityManagerResp == "Error") {
        setMainRespStat("error");
      } else if (
        singleFacilityManagerResp.consumptionSummary?.data.length == 0 && singleFacilityManagerResp.consumptionSummary?.assetdetails.length == 0
      ) {
        setMainRespStat("empty");
      } else {
        setMainRespStat("resp");
      }
    } else {
      if (singleFacilityManagerResp == "Error") {
        setDropdownRespStat("error");
      } else if (
        singleFacilityManagerResp.consumptionSummary?.data.length == 0 && singleFacilityManagerResp.consumptionSummary?.assetdetails.length == 0
      ) {
        setDropdownRespStat("empty");
      } else {
        setDropdownRespStat("resp");
      }
    }
    try {
      const newRespData = { ...singleFacilityManagerResp?.consumptionSummary };
    const { assetdetails } = singleFacilityManagerResp?.consumptionSummary;
    const sortedMainsArr = assetdetails.filter((obj: any) => obj.assets.classEnumDesc !== "Sub meter").sort((a: any, b: any) => b.assets.fixedAssetId - a.assets.fixedAssetId);
    const sortedArr = assetdetails.filter((obj: any) => obj.assets.classEnumDesc === "Sub meter").sort((a: any, b: any) => b.assets.fixedAssetId - a.assets.fixedAssetId);
    newRespData.assetdetails = [...sortedMainsArr, ...sortedArr];
    setSingleFacilityData(newRespData);

    } catch (err) {
      console.log(err)
    }
    
  };
  const abortController = useRef(new AbortController());
  useEffect(() => {
    const controller = new AbortController();
    if (dropDownDisplayType !== "") {
      const facilityList = localStorage.getItem("facilityIds");
      const firstFacilityId = JSON.parse(facilityList)[0].facilityId;
      const date2 = moment(dateRange[1], "YYYY-MM-DD HH:mm:ss");

      const dateType =
        dateChip === "Today"
          ? "day"
          : dateChip === "This Week"
            ? "week"
            : dateChip === "This Month"
              ? "month"
              : false;
      if (dateType) {
        if (dropDownDisplayType.split(" ").length !== 3) {
          getSingleFacilityData(
            dateRange,
            "facility",
            firstFacilityId,
            dateType,
            controller
          );
          return;
        }
        let startDateMilliSeconds = dropDownDisplayType.split(" ");
        let startDate = date2
          .clone()
          .subtract(+startDateMilliSeconds[1] - 1, dateType)
          .utc()
          .valueOf();
        let endDate = date2.valueOf();
        getSingleFacilityData(
          dateRange,
          "facility",
          firstFacilityId,
          `${dateType},${startDate},${endDate}`,
          controller
        );
        return;
      } else {
        getSingleFacilityData(dateRange, "facility", firstFacilityId, "day", controller);
      }
      abortController.current.abort();
      abortController.current = controller;
    }
    return () => {
      controller.abort();
    };
  }, [dropDownDisplayType]);

  const formatter = new Intl.NumberFormat('en-IN', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <>
      {" "}
      {mainRespStat === "load" ? (
        <div className="loader-cont">
          <LoaderStyle />
        </div>
      ) : mainRespStat === "empty" ? (
        <DisplayError type={"err-empty"} />
      ) : mainRespStat === "error" ? (
        <DisplayError type={"err-500/404"} />
      ) : (
        <div className="energyConsumptionContainer1">
  <div className="energyConsumptionDetailsContainer1">
    <div className="energyStatsContainer1">
      <div className="statsDetailsDiv1">
        <p className="statsHeader1">Total Energy Consumption</p>
        <div className="consumptionDetails1">
          <img src={EnergyIcon} alt="icon" className="statsImage1" />
          <h4 className="consumptionCount1">
            {
              !R.isEmpty(singleFacilityData?.all?.energyConsumption) &&
              !R.isNil(singleFacilityData?.all?.energyConsumption) &&
              singleFacilityData?.all?.energyConsumption !== 'undefined' &&
              typeof singleFacilityData?.all?.energyConsumption === 'number'
                ? formatter.format(singleFacilityData?.all?.energyConsumption).toLocaleString()
                : '-'
            }
            { 
              !R.isEmpty(singleFacilityData?.all?.energyConsumption) &&
              !R.isNil(singleFacilityData?.all?.energyConsumption) &&
              singleFacilityData?.all?.energyConsumption !== 'undefined' &&
              typeof singleFacilityData?.all?.energyConsumption === 'number'
                ? ` ${singleFacilityData?.all?.abbreviation}`
                : ''
            }
          </h4>
        </div>
      </div>
      <div className="borderContainer1"></div>
      <div className="statsDetailsDiv1">
        <p className="statsHeader1">Estimated Cost</p>
        <div className="consumptionDetails1">
          <img src={CostIcon} alt="icon" className="statsImage1" />
          <h4 className="consumptionCount1">
            {singleFacilityData?.all?.costUnit}{' '}
            {
              !R.isEmpty(singleFacilityData?.all?.estimatedCost) &&
              !R.isNil(singleFacilityData?.all?.estimatedCost) &&
              singleFacilityData?.all?.estimatedCost !== 'undefined' &&
              typeof singleFacilityData?.all?.estimatedCost === 'number'
                ? formatter.format(singleFacilityData?.all?.estimatedCost).toLocaleString()
                : '-'
            }
          </h4>
        </div>
      </div>
    </div>

    <div className="subMetersCardsContainer1">
      <div className="meterCardsContainer1">
        {singleFacilityData?.assetdetails?.map((obj: any) => (
          <MeterCard
            active={activeMeter === obj.assets.fixedAssetId}
            changeMeterId={changeMeterId}
            key={obj.assets.fixedAssetId}
            data={obj.assets}
          />
        ))}
      </div>
      <div className="energyConsumptionsCardsCont1">
        {dateChip !== "Today" && (
          <div className="consumptionsCard1">
            <p className="cardHeader1">Average Consumption</p>
            <div className="energyConsumptionReadingCont1">
              <img src={AvgConsumptionIcon} alt="icon" />
              <p className="subMetersText1">
                {activeMeter == ""
                  ? !R.isEmpty(singleFacilityData?.all?.averageConsumptionPerDay) &&
                    !R.isNil(singleFacilityData?.all?.averageConsumptionPerDay) &&
                    singleFacilityData?.all?.averageConsumptionPerDay !== 'undefined' &&
                    typeof singleFacilityData?.all?.averageConsumptionPerDay === 'number'
                    ? formatter.format(singleFacilityData?.all?.averageConsumptionPerDay).toLocaleString()
                    : '-'
                  : !R.isEmpty(meterAssetData?.averageConsumptionPerDay) &&
                    !R.isNil(meterAssetData?.averageConsumptionPerDay) &&
                    meterAssetData?.averageConsumptionPerDay !== 'undefined' &&
                    typeof meterAssetData?.averageConsumptionPerDay === 'number'
                    ? formatter.format(meterAssetData?.averageConsumptionPerDay).toLocaleString()
                    : '-'}
                {" "}{activeMeter == ""
                  ? singleFacilityData?.all?.abbreviation
                  : meterAssetData.abbreviation}{" "}
                <span className="subMeterSpan1">per day</span>
              </p>
            </div>
          </div>
        )}

        <div className="consumptionsCard1" style={{ width: dateChip === "Today" ? "49%" : "" }}>
          <p className="cardHeader1">Average Power Factor</p>
          <div className="energyConsumptionReadingCont1">
            <img src={AvgPowerFactorIcon} alt="icon" />
            <p className="subMetersText1">
              {activeMeter == ""
                ? !R.isEmpty(singleFacilityData?.all?.powerFactor) &&
                  !R.isNil(singleFacilityData?.all?.powerFactor) &&
                  singleFacilityData?.all?.powerFactor !== "undefined" &&
                  typeof singleFacilityData?.all?.powerFactor === 'number'
                  ? formatter.format(singleFacilityData?.all?.powerFactor).toLocaleString()
                  : '-'
                : !R.isEmpty(meterAssetData?.powerFactor) &&
                  !R.isNil(meterAssetData?.powerFactor) &&
                  meterAssetData?.powerFactor !== 'undefined' &&
                  typeof meterAssetData?.powerFactor === 'number'
                  ? roundToDecimalNumber(meterAssetData?.powerFactor, 2)
                  : '-'}
            </p>
          </div>
        </div>

        <div className="consumptionsCard1" style={{ width: dateChip === "Today" ? "49%" : "" }}>
          <p className="cardHeader1">Apparent Power</p>
          <div className="energyConsumptionReadingCont1">
            <img src={ApparentPowerIcon} alt="icon" />
            <p className="apparentText1">
              {activeMeter == ""
                ? !R.isEmpty(singleFacilityData?.all?.apparentPower) &&
                  !R.isNil(singleFacilityData?.all?.apparentPower) &&
                  singleFacilityData?.all?.apparentPower !== 'undefined' &&
                  typeof singleFacilityData?.all?.apparentPower === 'number'
                  ? formatter.format(singleFacilityData?.all?.apparentPower).toLocaleString()
                  : '-'
                : !R.isEmpty(meterAssetData?.apparentPower) &&
                  !R.isNil(meterAssetData?.apparentPower) &&
                  meterAssetData?.apparentPower !== 'undefined' &&
                  typeof meterAssetData?.apparentPower === 'number'
                  ? formatter.format(meterAssetData?.apparentPower).toLocaleString()
                  : '-'}
              {" "}
              {singleFacilityData?.all?.apparentPowerabbr}
            </p>
          </div>
          {activeMeter === "" && (
            <p className="apparentText1">
              Contract Demand{" "}
              <span>
                {singleFacilityData?.all?.contractDemand
                  ? formatter.format(singleFacilityData?.all?.contractDemand).toLocaleString()
                  : '-'}{" "}
                {singleFacilityData?.all?.apparentPowerabbr}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>

    <div className="averageReactivePower1">
      <p className="cardHeader1">Average Reactive Power</p>
      <div className="energyConsumptionReadingCont1">
        <img src={AverageReactPowerIcon} alt="icon" />
        <p className="apparentText1">
          {activeMeter == ""
            ? !R.isEmpty(singleFacilityData?.all?.reactivePower) &&
              !R.isNil(singleFacilityData?.all?.reactivePower) &&
              singleFacilityData?.all?.reactivePower !== 'undefined' &&
              typeof singleFacilityData?.all?.reactivePower === 'number' &&
              formatter.format(singleFacilityData?.all?.reactivePower).toLocaleString()
            : !R.isEmpty(meterAssetData?.reactivePower) &&
              !R.isNil(meterAssetData?.reactivePower) &&
              meterAssetData?.reactivePower !== 'undefined' &&
              typeof meterAssetData?.reactivePower === 'number' &&
              formatter.format(meterAssetData?.reactivePower).toLocaleString()}
          {" "}{activeMeter == ""
            ? singleFacilityData?.all?.reactivePowerabbr
            : meterAssetData.reactivePowerabbr}{" "}
        </p>
      </div>
    </div>

    <div className="filterButtonsContainer1">
      <div className="buttonsCont1">
        <button className="activeConsumptionsCont1">Total Energy Consumption</button>
      </div>
    </div>

    <div className="filterTabsContainer1">
      {dropDownDisplayType !== 'Custom Range' && (
        <div className="filterContainer1">
          <div
            className="filterBtnCont1"
            onClick={() => showDropDownList(!showDropDown)}
          >
            <p className="filterHeader1">Viewing {dropDownDisplayType}</p>
            <img src={FilterIcon} alt="icon" />
          </div>
          {showDropDown && (
            <ul className="selectorContainer1">
              {dropDownList.map((list) => (
                <li
                  style={{
                    cursor: "pointer",
                    color: dropDownDisplayType === list ? "white" : "black",
                    backgroundColor: dropDownDisplayType === list ? "#0075FF" : "",
                  }}
                  key={list}
                  onClick={() => {
                    showDropDownList(false);
                    setDropDownDisplayType(list);
                    setAPICallType("Sub");
                  }}
                >
                  {list}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}

      {activeMeter === "" && (
        <div className="filterTypesCont1">
          <div className="filterType1">
            <img src={EnergyConsumptionIcon} alt="icon" />
            <p className="filterTypeHeader1">Energy Consumption</p>
          </div>
          <div className="filterType1">
            <img src={EstimatedCostIcon} alt="icon" />
            <p className="filterTypeHeader1">Estimated Cost</p>
          </div>
        </div>
      )}
    </div>
    {dropDownRespStat === "load" ? (
      <div className="loader-cont1">
        <LoaderStyle />
      </div>
    ) : dropDownRespStat === "empty" ? (
      <DisplayError type={"err-empty"} />
    ) : dropDownRespStat === "error" ? (
      <DisplayError type={"err-500/404"} />
    ) : (
      <>
        {" "}
        {activeMeter === "" ? (
          <div>
            {" "}
            <ReactEcharts style={{ height: "400px" }} option={chartOptions} />{" "}
          </div>
        ) : (
          <div>
            <ReactEcharts style={{ height: "400px" }} option={meterOptions} />
          </div>
        )}
      </>
    )}
  </div>
  <div className="viewAssetNavCont1">
    <Link to={"/asset-list"} className="viewAssetText1">{`View Assets>`}</Link>
  </div>
</div>

      )}
    </>
  );
}

export default SingleFacilityEnergyConsumption;
